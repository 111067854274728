export default {
  'login.lock.account.title': 'Khóa tài khoản',
  'login.lock.account.notification':
    'Do bạn nhập sai mã OTP 5 lần nên tài khoản của bạn đã bị khóa. Vui lòng liên lạc nhân viên hỗ trợ để biết thêm thông tin.',
  'login.check.captcha': 'Vui lòng kiểm tra mã captcha',
  'login.button.account': 'Đăng nhập',
  'login.button.mobile': 'Nhận mã OTP',
  'login.button.by.account': 'Đăng nhập bằng tài khoản',
  'login.button.by.mobile': 'Đăng nhập bằng số điện thoại',
  'login.button.contact.support': 'Liên lạc hỗ trợ',
  'login.title': 'Đăng nhập',
  'login.userName': 'Tên đăng nhập. Vd: someone@example',
  'login.password': 'Mật khẩu',
  'login.account.error': 'Sai tên đăng nhập hoặc mật khẩu.',
  'login.mobile.error': 'Sai số điện thoại',
  'login.remember': 'Ghi nhớ đăng nhập',
  'login.forgot.password': 'Quên mật khẩu?',
  'login.required.username': 'Vui lòng nhập tên đăng nhập',
  'login.required.password': 'Vui lòng nhập mật khẩu',
};
