export default {
  'login.lock.account.title': 'Lock account',
  'login.lock.account.notification':
    'Because you enter the wrong OTP code 5 times, your account has been locked.Please contact the support staff for more information.',
  'login.check.captcha': 'Please check the CAPTCHA code',
  'login.button.account': 'Log in',
  'login.button.mobile': 'Get OTP code',
  'login.button.by.account': 'Log in with an account',
  'login.button.by.mobile': 'Log in with phone numbers',
  'login.button.contact.support': 'Contact support',
  'login.title': 'Log in',
  'login.userName': 'Username. Ex: someone@example',
  'login.password': 'Password',
  'login.account.error': 'Wrong username or password.',
  'login.mobile.error': 'Wrong phone number',
  'login.remember': 'Remember me',
  'login.forgot.password': 'Forgot password?',
  'login.required.username': 'Please enter username',
  'login.required.password': 'Please enter password',
};
