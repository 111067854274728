export default {
  'server.notFound': 'Not found',
  'server.networkError': 'Network error',
  'Server.AccountEmail.NotExists': 'Incorrect login information',
  'Server.AddBeat.Success': 'Successfully added',
  'Server.DeleteBeat.Success': 'Successfully deleted',
  'Server.UpdateBeat.Success': 'Successfully updated',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Incorrect username or password',
  'Mes.Users.Login.Successfully': 'Login successful',
  'Mes.Classes.Year.MustHave2Characters': 'Academic year must have 2 characters',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Class code must have 2 characters',
  'Mes.Classes.Add.Successfully': 'Class added successfully',
  'Mes.Questions.Update.Successfully': 'Question updated successfully',
  'Mes.Questions.Add.Successfully': 'Question added successfully',
  'Mes.Users.UpdateSuccessfully': 'Account information updated successfully',
  'Mes.Users.Add.Successfully': 'Account added successfully',
  'Mes.Users.DeleteUsers.Successfully': 'Successfully deleted',
  'Mes.Researchs.Update.Successfully': 'Research updated successfully',
  'Mes.Researchs.Add.Successfully': 'Research added successfully',
  'Mes.Researchs.Delete.Successfully': 'Research deleted successfully',
  'server.data.success': 'Success',
  Successful: 'Success',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'Recovery link has been sent to your email',
  'Mes.User.ResetPasswordSuccessfully': 'Password reset successfully',
  'Mes.Profile.GetDetailSuccessfully': 'Account information retrieved successfully',
  'Mes.Profile.OldPassword.Wrong': 'Old password is incorrect',
  'Mes.Profile.ChangePasswordSuccessfully': 'Password updated successfully',
  'Mes.Role.UpdateSuccessfully': 'Role updated successfully',
  'Mes.Role.CreateSuccessfully': 'Role added successfully',
  'Mes.Role.DeleteSuccessfully': 'Role deleted successfully',

  'Mes.User.Invalid.Username': 'Invalid username',
  'Mes.User.AlreadyExist.Username': 'Username already exists',
  'Mes.User.Required.Username': 'Username is required',
  'Mes.User.NotWhiteSpace.Username': 'Username cannot contain whitespace',
  'Mes.User.NotSpecialCharacter.Username':
    'Username cannot contain special characters (except . @)',
  'Mes.User.Invalid.Email': 'Invalid email',
  'Mes.User.AlreadyExist.Email': 'Username already exists',
  'Mes.User.Required.Email': 'Email is required',
  'Mes.User.NotWhiteSpace.Email': 'Email cannot contain whitespace',
  'Mes.User.OverLength.Name': 'User name must not exceed 250 characters',
  'Mes.User.Invalid.AvatarPicture': 'Invalid avatar picture',
  'Mes.User.Invalid.Password': 'Invalid password',
  'Mes.User.Required.Password': 'Password is required',
  'Mes.User.IsWrong.OldPassword': 'Old password is incorrect',
  'Mes.User.Invalid.OldPassword': 'Invalid old password',
  'Mes.User.Invalid.PhoneNumber': 'Invalid phone number',
  'Mes.User.Required.RoleId': 'Role ID is required',
  'Mes.User.Status.Required': 'Status is required',
  'Mes.User.Status.Invalid': 'Invalid status',
  'Mes.User.Required.RecoveryCode': 'Recovery code is required',
  'Mes.User.NotFound.RecoveryCode': 'User recovery code not found',
  'Mes.User.NotFound': 'User not found',
  'Mes.User.PleaseCheckEmail': 'Please check your email',
  'Mes.User.Blocked': 'User has been blocked',
  'Mes.UserRefreshToken.Required.Token': 'Refresh token is required',
  'Mes.UserRefreshToken.WasUsed': 'Refresh token has been used',
  'Mes.UserRefreshToken.Invalid.Token': 'Invalid refresh token',
  'Mes.UserRefreshToken.Expired.Token': 'Refresh token has expired',
  'Mes.User.Login.Successfully': 'Login successful',
  'Mes.User.NotFound.Email': 'Email not found',
  'Mes.OAuth.InvalidFormat.RedirectUri': 'Invalid redirect URI format',
  'Mes.User.Required.Name': 'User name is required',
  'Mes.Role.Create.Successfully': 'Role created successfully',
  'Mes.Role.Create.Failed': 'Role creation failed',
  'Mes.Role.Update.Successfully': 'Role updated successfully',
  'Mes.Role.Update.Failed': 'Role update failed',
  'Mes.Role.Search.Successfully': 'Role list retrieved successfully',
  'Mes.Role.ViewDetail.Successfully': 'Role details retrieved successfully',
  'Mes.Role.Delete.Successfully': 'Role deleted successfully',
  'Mes.Role.Delete.Failed': 'Role deletion failed',
  'Mes.Role.Required.PermissionCodes': 'Permission codes are required',
  'Mes.Role.OverLength.Code': 'Role code must not exceed 255 characters',
  'Mes.Role.OverLength.Description': 'Role description must not exceed 255 characters',
  'Mes.Role.AlreadyExist.Code': 'Role code already exists',
  'Mes.Role.Required.Code': 'Role code is required',
  'Mes.Role.NotWhiteSpace.Code': 'Role code cannot contain whitespace',
  'Mes.Role.NotSpecialCharacter.Code': 'Role code cannot contain special characters (except . @)',
  'Mes.Role.Required.Name': 'Role name is required',
  'Mes.Role.OverLength.Name': 'Role name must not exceed 255 characters',
  'Mes.Role.NotFound': 'Role not found',
  'Mes.Role.Repeated.Ids': 'Role ID cannot be repeated',
  'Mes.Role.Required.Ids': 'Role ID is required',
  'Mes.Role.WasUsed': 'Role has been used',
  'Mes.User.Create.Successfully': 'User created successfully',
  'Mes.User.Create.Failed': 'User creation failed',
  'Mes.User.Update.Successfully': 'User updated successfully',
  'Mes.User.Update.Failed': 'User update failed',
  'Mes.User.Search.Successfully': 'User list retrieved successfully',
  'Mes.User.ViewDetail.Successfully': 'User details retrieved successfully',
  'Mes.User.Delete.Successfully': 'User deleted successfully',
  'Mes.User.Delete.Failed': 'User deletion failed',
  'Mes.User.Send.Failed': 'Failed to send email to user',
  'Mes.User.CheckRecoveryCode.Successfully': 'Recovery code verified successfully',
  'Mes.User.SendRecoveryCode.Successfully': 'Recovery code sent successfully',
  'Mes.User.ResetPassword.Successfully': 'Password reset successfully',
  'Mes.User.Refresh.Successfully': 'Refresh successful',
  'Mes.User.ChangePassword.Successfully': 'Password changed successfully',
  'Mes.User.Profile.Successfully': 'Profile information retrieved successfully',
  'Mes.User.Required.TimezoneId': 'Timezone ID is required',
  'Mes.User.Required.NotFound': 'Timezone not found',
  'Mes.User.Required.RedirectUri': 'Redirect URL is required',
  'Mes.User.OverLength.RedirectUri': 'Redirect URL must not exceed 255 characters',
  'Mes.User.Required.Code': 'Login code is required',
  'Mes.User.OverLength.Code': 'Login code must not exceed 255 characters',
  'Mes.User.Required.PhoneNumber': 'Phone number is required',
  'Mes.User.OverLength.IdentifierNumber': 'Identification number must not exceed 50 characters',
  'Mes.User.Invalid.IdentifierNumber': 'Invalid identification number',
  'Mes.User.Repeated.Ids': 'User ID cannot be repeated',
  'Mes.User.Required.Ids': 'User ID is required',
  'Mes.User.NotAllowed': 'User is not allowed',
  'Mes.SsoResult.Empty.Id': 'Could not find user ID on SSO',
  'Mes.SsoAuthentication.Failed': 'SSO login failed',
  'Mes.SsoResult.Empty.UserName': 'Could not find username on SSO',
  'Messages.SsoAuthentication.Required.AccessToken': 'Access token is required for SSO login',
  'Mes.SsoAuthentication.Invalid.Email': 'SSO validation failed: Invalid email',
  'Mes.SsoAuthentication.Invalid.PhoneNumber': 'SSO validation failed: Invalid phone number',
  'Mes.SsoAuthentication.Invalid.Username': 'SSO validation failed: Invalid username',
  'Mes.SsoAuthentication.Invalid.Groups':
    'Invalid SSO user group. Please contact the system administrator for assistance!',
  'Mes.SsoAuthentication.Invalid.ClientId':
    'Invalid ClientId. Please contact the system administrator for assistance!',
  'Mes.SsoAuthentication.Invalid.ClientSecret':
    'Invalid ClientSecret. Please contact the system administrator for assistance!',
  'Mes.SsoAuthentication.Invalid.GrantType':
    'Invalid GrantType. Please contact the system administrator for assistance!',
  'Mes.UserMobileDevice.Required.DeviceId': 'Device registration ID is required',
  'Mes.UserMobileDevice.OverLength.DeviceId':
    'Device registration ID must not exceed 255 characters',
  'Mes.User.NotFound.Username': 'User not found',
};
