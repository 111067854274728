export default {
  'adminAccount.router': 'Tài khoản quản trị',
  'adminAccount.title': 'Danh sách tài khoản',
  'adminAccount.userName': 'Tên đăng nhập',
  'adminAccount.fullName': 'Họ và tên',
  'adminAccount.phoneNumber': 'Số điện thoại',
  'adminAccount.email': 'Email',
  'adminAccount.status': 'Trạng thái',
  'adminAccount.create': 'Thêm tài khoản quản trị mới',
  'adminAccount.information': 'Thông tin tài khoản quản trị',
  'adminAccount.update': 'Cập nhật tài khoản quản trị',
  'adminAccount.userFullName': 'Họ và tên',
  'adminAccount.userEmail': 'Email',
  'adminAccount.userAvatar': 'Chọn ảnh đại diện',
  'adminAccount.userPhone': 'Số điện thoại',
  'adminAccount.identifierNumber': 'CMND/CCCD',
  'adminAccount.address': 'Địa chỉ',
  'adminAccount.isActive': 'Trạng thái hoạt động',
  'adminAccount.status.active': 'Đang hoạt động',
  'adminAccount.status.notActive': 'Ngưng hoạt động',
  'adminAccount.delete.title': 'Xác nhận xóa tài khoản',
  'adminAccount.delete.content':
    'Các tài khoản quản trị địa bàn được chọn sẽ bị xóa và không thể khôi phục.',
  'adminAccount.validator-userName': 'Vui lòng nhập tên đăng nhập',
  'adminAccount.validatorUserName': 'Tên đăng nhập không đúng định dạng',
  'adminAccount.validator-userFullName': 'Vui lòng nhập họ và tên',
  'adminAccount.validatorUndefined': 'Họ và tên không đúng định dạng',
  'adminAccount.validate-phone': 'Số điện thoại không đúng định dạng',
  'adminAccount.password': 'Mật khẩu',
  'adminAccount.password.required.1': 'Vui lòng nhập mật khẩu',
  'adminAccount.password.required.2':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'adminAccount.confirmPassword': 'Xác nhận mật khẩu',
  'adminAccount.permissions': 'Phân quyền',
};
