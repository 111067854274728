import Cookies from 'js-cookie';

import store from '@core/store/redux';

import profileStore, { setToken } from './profileStore';
import * as authenticationRepository from './repository';

const authenticationPresenter = { ...authenticationRepository };

authenticationPresenter.login = async (code, remember = false) => {
  const token: any = await authenticationRepository.login(code);
  store.dispatch(
    setToken({ token: token.accessToken, refreshToken: token.refreshToken, remember }),
  );
  Cookies.set('accessTokenSSO', token.accessToken, { expires: 7 });
  Cookies.set('refreshTokenSSO', token.refreshToken, { expires: 7 });
  return token;
};
authenticationPresenter.resetToken = payload => {
  return authenticationRepository.resetToken(payload).then((res: any) => {
    store.dispatch(profileStore.actions.resetToken(res));
    Cookies.set('accessTokenSSO', res.accessToken, { expires: 7 });
    Cookies.set('refreshTokenSSO', res.refreshToken, { expires: 7 });
    return Promise.resolve(res);
  });
};
authenticationPresenter.getProfile = () => {
  return authenticationRepository.getProfile().then((user: any) => {
    store.dispatch(
      profileStore.actions.fetchProfile({
        user,
        listPermissionCode: user.permissions?.map(it => it.code),
      }),
    );
    Cookies.set('usernameSSO', user?.username, { expires: 7 });
    return Promise.resolve(user);
  });
};

export default authenticationPresenter;
